import { of } from 'rxjs'
import { map, catchError } from 'rxjs/operators';

export class CacheService {
    constructor() {
        this._cache = {};
        this._observableCache = {};
    }

    addCache(key, value, minutes = 5) {
        let time = minutes * 60 * 1000;
        let item = {
            value,
            validTo: time ? (new Date()).getTime() + time : null
        };
        this._cache[key] = item;
    }

    getCache(key) {
        let item = this._cache[key];
        if (!item) return undefined;

        if (!item.validTo || item.validTo >= (new Date()).getTime()) return item.value;
        return undefined;
    }

    //fn should return Observable
    fromCache(key, fn, minutes = 5) {
        let value = this.getCache(key);
        if (value) return of(value);
        if (this._observableCache[key]) return this._observableCache[key];

        this._observableCache[key] = fn().pipe(map(value => {
            delete this._observableCache[key];
            this.addCache(key, value, minutes);
            return value;
        }), catchError(err => {
            delete this._observableCache[key];
            throw err;
        }));

        return this._observableCache[key];
    }

    clearCache(key) {
        if (key === undefined) this._cache = {};
        else delete this._cache[key];
    }
}