import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';

import Resize from './resize';
import { commonService } from '../services/imports';

export default class TooltipComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            render: !props.onlyTruncated
        };

        this.identifier = `${commonService.guid().replaceAll('-', '')}`;
    }

    render() {
        const { onlyTruncated, tooltip, children, ...rest } = this.props;

        if (!onlyTruncated && !tooltip) return <React.Fragment>{children}</React.Fragment>;

        const child = React.Children.only(children);
        const element = React.cloneElement(child, {
            'data-tooltip': this.identifier
        });

        if (!onlyTruncated) return <React.Fragment>
            {element}
            <UncontrolledTooltip target={`[data-tooltip='${this.identifier}']`} {...rest}>
                {tooltip}
            </UncontrolledTooltip>
        </React.Fragment>

        return <React.Fragment>
            <Resize onResize={e => { this.setState({ render: e.isTruncated }) }}>
                {element}
            </Resize>
            {
                this.state.render &&
                <UncontrolledTooltip target={`[data-tooltip='${this.identifier}']`} {...rest}>
                    {tooltip}
                </UncontrolledTooltip>
            }
        </React.Fragment>
    }
}