
import React from 'react';
import { CommonRoute } from './commonRoute';

import { Navigate } from 'react-router-dom';
import { routingService, authService } from '../../services/imports';

export class PrivateRoute extends CommonRoute {
    constructor(props) {
        super(props);
    }

    render() {
        let hasPermission = true;
        const permission = this.props.permission;

        if (typeof permission === "boolean") {
            hasPermission = permission;
        }
        else if (permission) {
            if (typeof permission === "string") hasPermission = authService.hasPermission(permission);
            else if (typeof permission === "function") hasPermission = permission();
        }

        if (!authService.isAuthenticated()) return (<Navigate replace to={routingService.routePath('login')} />);
        if (!hasPermission) return (<Navigate replace to={routingService.routePath('home')} />);

        return super.render();
    }
}

export function privateRoute(component, props) {
    return <PrivateRoute component={component} {...props} />
}