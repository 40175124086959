import './lib';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { routingService } from './services/imports';

import { ReactNotifications } from 'react-notifications-component';
import App from './App';

const router = createBrowserRouter([
  {
    path: "*",
    element: <App />
  },
]);
routingService.initRouter(router);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.Fragment>
    <ReactNotifications />

    <RouterProvider router={router} />
  </React.Fragment>
);
