import React, { Suspense } from "react";
import logo from './assets/images/logo.png';

import { Routes, Route } from "react-router-dom";
import { noRouteMatched, commonRoute, privateRoute, Link } from "./components/routing/imports";
import Tooltip from './components/tooltip';

import { authService, commonService, routingService, stateService, htmlHelperService, dictionaryService } from "./services/imports";
import { states, stateEvents, claims } from "./models/imports";

import LoginPage from './pages/login';
import HomePage from "./pages/home/index";
import ProfilePage from "./pages/profile";

const CompaniesPage = React.lazy(() => import('./pages/central/companies'));
const InvoicesPage = React.lazy(() => import('./pages/invoice/invoices'));
const PaymentsPage = React.lazy(() => import('./pages/payment/payments'));
const ReportsPage = React.lazy(() => import('./pages/report/reports'));
const UserPage = React.lazy(() => import('./pages/user/index'));
const SettingPage = React.lazy(() => import('./pages/setting/settings'));

export default class AppComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      initialized: false,
      settingsVisible: false,
      leftMenuCollapsed: false,

      userInfo: {},
      permissions: {},
    };

    this.subscriptions = [
      commonService.registerCommonInterceptor(),
      authService.registerAuthInterceptor(),
      routingService.listen().subscribe(() => this.forceUpdate())
    ];

    this.onBlurSub = null;
  }

  toggleSettings = () => {
    this.setState(state => ({ settingsVisible: !state.settingsVisible }));
    if (this.onBlurSub) return;

    this.onBlurSub = htmlHelperService.onBlur(this.settings, this.settingsContainer)
      .subscribe(() => {
        this.setState({ settingsVisible: false });
      });
  }

  toggleMenu = () => {
    this.setState(state => ({ leftMenuCollapsed: !state.leftMenuCollapsed }), () => {
      localStorage.setItem('leftMenuCollapsed', this.state.leftMenuCollapsed.toString());
    });

  }

  initialize = () => {
    this.setState({ initialized: true, settingsVisible: false, leftMenuCollapsed: localStorage.getItem('leftMenuCollapsed') === 'true' });

    if (this.onBlurSub) {
      this.onBlurSub.unsubscribe();
      this.onBlurSub = null;
    }
  };

  logout = () => {
    authService.logout();
  };

  //global catch for render error
  componentDidCatch(error, info) {
    console.error(error);
  }

  componentDidMount() {
    this.subscriptions.push(
      stateService.onEvent(stateEvents.signOut).subscribe(() => {
        stateService.clearState();
        if (!routingService.is('login')) routingService.push("login");
      })
    );

    this.subscriptions.push(
      stateService.onEvent(stateEvents.refreshConfig, true).subscribe(() => {
        dictionaryService.getConfig().subscribe(data => {
          stateService.setState(states.config, data);
        });
      }));

    this.subscriptions.push(authService.user.subscribe(info => {
      const changed = info?.userId && this.state.userInfo?.userId !== info?.userId;
      this.setState({ userInfo: info || {} }, () => {
        if (!changed) return;

        const permissions = {
          userManagement: authService.hasPermission(claims.UserManagement),
          settings: authService.hasPermission(claims.Settings),

          companies: authService.hasPermission(claims.CustomerCompanies),
          invoices: authService.hasPermission(claims.Invoices),
          payments: authService.hasPermission(claims.Payments),
          reports: authService.hasPermission(claims.Reports)
        };

        if (permissions.userManagement || permissions.settings)
          permissions.admin = true;

        this.setState({ permissions });
        this.initialize();
      });
    })
    );

    if (!authService.isAuthenticated()) {
      this.initialize();
      return;
    }

    this.subscriptions.push(
      authService.getUserInfo().subscribe({
        next: info => {
          authService.setUser(info || {});
          this.initialize();
        },
        error: err => {
          authService.logout();
          this.initialize();
        }
      })
    );
  }

  //free subscriptions
  componentWillUnmount() {
    for (let sub of this.subscriptions) {
      sub.unsubscribe();
    }

    if (this.onBlurSub) this.onBlurSub.unsubscribe();
    this.subscriptions = [];
  }

  render() {
    if (!this.state.initialized) return null;
    const { settingsVisible, leftMenuCollapsed, userInfo, permissions } = this.state;
    const isAuthenticated = authService.isAuthenticated();

    return (
      <React.Fragment>
        <div className={`h-100 ${leftMenuCollapsed ? 'menu-collapsed' : ''}`}>
          <nav className="top-menu">
            <h4 className="float-left mt-1 menu-icon"><img height="25" src={logo} /> <span className="ml-1">FitPass Invoicing</span></h4>
            <h6 className="float-left mt-2 ml-3"></h6>
            {
              isAuthenticated &&
              <div className="float-right mt-1 mr-2">
                <div className="d-flex">
                  <h6 className="mr-4 mt-1">
                    {userInfo.firstName || userInfo.fullName} {userInfo.lastName}
                    {
                      !!userInfo.companyName &&
                      <i className="ml-1">({userInfo.companyName})</i>
                    }
                  </h6>
                  <i ref={el => { this.settings = el; }} className="fa fa-2x fa-bars clickable"
                    onClick={this.toggleSettings}></i>
                </div>

                <div ref={el => { this.settingsContainer = el; }}
                  className={`settings-content shadow-sm p-3 mb-5 bg-white rounded ${settingsVisible ? '' : 'd-none'}`}>

                  <Link className="btn btn-light btn-block" to="profile"
                    onClick={e => { this.setState({ settingsVisible: false }); }}>პროფილი</Link>
                  <button className="btn btn-danger btn-block mt-1" onClick={this.logout}>გამოსვლა</button>
                </div>
              </div >
            }
          </nav>
          {
            isAuthenticated &&
            <nav className="left-menu">
              <div className="expander" onClick={this.toggleMenu}>
                <i className={`fa ${leftMenuCollapsed ? 'fa-angle-double-right' : 'fa-angle-double-left'}`}></i>
              </div>

              <Tooltip placement="right" tooltip={leftMenuCollapsed && "მთავარი"}>
                <Link to="home" className={`left-menu-item ${routingService.is('home') ? 'active' : ''}`}>
                  <i className="fa fa-home"></i> <span>მთავარი</span>
                </Link>
              </Tooltip>

              {
                permissions.companies &&
                <React.Fragment>
                  <div className="separator"></div>
                  <Tooltip placement="right" tooltip={leftMenuCollapsed && "კომპანიები"}>
                    <Link to="companies" className={`left-menu-item ${routingService.is('companies') ? 'active' : ''}`}>
                      <i className="fa fa-building"></i> <span>კომპანიები</span>
                    </Link>
                  </Tooltip>
                </React.Fragment>
              }

              {
                permissions.invoices &&
                <React.Fragment>
                  <div className="separator"></div>
                  <Tooltip placement="right" tooltip={leftMenuCollapsed && "ინვოისები"}>
                    <Link to="invoices" className={`left-menu-item ${routingService.is('invoices') ? 'active' : ''}`}>
                      <i className="fa fa-tasks"></i> <span>ინვოისები</span>
                    </Link>
                  </Tooltip>
                </React.Fragment>
              }

              {
                permissions.payments &&
                <React.Fragment>
                  <div className="separator"></div>
                  <Tooltip placement="right" tooltip={leftMenuCollapsed && "გადახდები"}>
                    <Link to="payments" className={`left-menu-item ${routingService.is('payments') ? 'active' : ''}`}>
                      <i className="fa fa-credit-card"></i> <span>გადახდები</span>
                    </Link>
                  </Tooltip>
                </React.Fragment>
              }

              {
                permissions.reports &&
                <React.Fragment>
                  <div className="separator"></div>
                  <Tooltip placement="right" tooltip={leftMenuCollapsed && "რეპორტები"}>
                    <Link to="reports" className={`left-menu-item ${routingService.is('reports') ? 'active' : ''}`}>
                      <i className="fa fa-bar-chart"></i> <span>რეპორტები</span>
                    </Link>
                  </Tooltip>
                </React.Fragment>
              }


              {
                permissions.admin &&
                <div className="separator title">ადმინისტრირება</div>
              }

              {
                permissions.userManagement &&
                <React.Fragment>
                  <Tooltip placement="right" tooltip={leftMenuCollapsed && "მომხმარებლები"}>
                    <Link to="users" className={`left-menu-item ${routingService.is('users') ? 'active' : ''}`}>
                      <i className="fa fa-user"></i> <span>მომხმარებლები</span>
                    </Link>
                  </Tooltip>
                  <div className="separator"></div>
                </React.Fragment>
              }

              {
                permissions.settings &&
                <React.Fragment>
                  <Tooltip placement="right" tooltip={leftMenuCollapsed && "პარამეტრები"}>
                    <Link to="settings" className={`left-menu-item ${routingService.is('settings') ? 'active' : ''}`}>
                      <i className="fa fa-cogs"></i> <span>პარამეტრები</span>
                    </Link>
                  </Tooltip>
                  <div className="separator"></div>
                </React.Fragment>
              }
            </nav>
          }

          <div className={`main-content ${isAuthenticated ? '' : 'pl-0'}`}>
            <div className="container-fluid h-100">
              <Suspense fallback={null}>
                <Routes>
                  <Route
                    path={routingService.routes.home}
                    exact
                    element={privateRoute(HomePage)}
                  />

                  <Route
                    path={routingService.routes.login}
                    exact
                    element={commonRoute(LoginPage)}
                  />

                  <Route
                    path={routingService.routes.profile}
                    exact
                    element={privateRoute(ProfilePage)}
                  />

                  <Route
                    path={routingService.routes.companies}
                    exact
                    element={privateRoute(CompaniesPage, { permission: claims.CustomerCompanies })}
                  />

                  <Route
                    path={routingService.routes.invoices}
                    exact
                    element={privateRoute(InvoicesPage, { permission: claims.invoices })}
                  />

                  <Route
                    path={routingService.routes.payments}
                    exact
                    element={privateRoute(PaymentsPage, { permission: claims.payments })}
                  />

                  <Route
                    path={routingService.routes.reports}
                    exact
                    element={privateRoute(ReportsPage, { permission: claims.reports })}
                  />

                  <Route
                    path={routingService.routes.users}
                    exact
                    element={privateRoute(UserPage, { permission: claims.UserManagement })}
                  />

                  <Route
                    path={routingService.routes.settings}
                    exact
                    element={privateRoute(SettingPage, { permission: claims.Settings })}
                  />

                  <Route path="*" element={noRouteMatched()} />
                </Routes>
              </Suspense>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
