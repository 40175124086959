import axios from 'axios';
import { from } from 'rxjs';
import { tap, map } from 'rxjs/operators';

class AttachmentService {
    download(attachmentId, open = true) {
        const promise = axios.get(`${process.env.REACT_APP_API_URL}Attachment/request`, { params: { attachmentId } });
        return from(promise).pipe(tap(data => {
            if (!open) return;

            window.open(data.url, '_blank');
        }));
    }

    upload(model) {
        var form = new FormData();
        form.append('Description', model.description);
        form.append('File', model.file);

        const promise = axios.post(`${process.env.REACT_APP_API_URL}Attachment/upload`, form, { headers: { "Content-Type": "multipart/form-data" } });
        return from(promise).pipe(map(data => {
            return {
                attachmentId: data.attachmentId,
                name: model.file.name,
                contentType: model.file.contentType,
                description: model.description,
                uploadDate: new Date()
            };
        }));
    }
}

export default new AttachmentService();