import React from 'react';

export default class ResizeComponent extends React.Component {
    calcHeight(el) {
        const isWidthTruncated = el.offsetWidth < el.scrollWidth;
        const isHeightTruncated = el.offsetHeight < el.scrollHeight;

        this.props.onResize && this.props.onResize({ isTruncated: isWidthTruncated || isHeightTruncated, isWidthTruncated, isHeightTruncated });
    }

    onSetRef = el => {
        if (this.observer) {
            this.observer.disconnect();
            this.observer = undefined;
        }

        if (!el) return

        if (ResizeObserver && el) {
            this.observer = new ResizeObserver((entries) => {
                this.calcHeight(el);
            });

            this.observer.observe(el);
        }

        this.calcHeight(el);

    }

    render() {
        const child = React.Children.only(this.props.children);

        return <React.Fragment>
            {
                React.cloneElement(child, {
                    ref: this.onSetRef
                })
            }
        </React.Fragment>
    }
}