import React from 'react';
import { routingService } from '../../services/imports';

export class CommonRoute extends React.Component {
    constructor(props) {
        super(props);

        this._prevPath = null;
        this._prevComponent = null;
    }

    render() {
        const { component, skipScrollTop, permission, ...props } = this.props;
        const history = routingService.routeChanged();

        if (history.location.pathname !== this._prevPath && !skipScrollTop) {
            window.scrollTo(0, 0);
        }

        let reload = false;
        if (history.location.pathname !== this._prevPath &&
            component.name === this._prevComponent) {
            reload = true;
        }

        this._prevPath = history.location.pathname;
        this._prevComponent = component.name;
       
        const Component = component;
        return <Container reload={reload} path={this._prevPath}>
            <Component {...props} />
        </Container>;
    }
}

export function commonRoute(component, props) {
    return <CommonRoute component={component} {...props} />
}

class Container extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            render: true
        };

        this.started = false;
        this.path = null;
    }

    componentDidUpdate() {
        if (!this.props.reload || this.started) return;

        this.started = true;
        this.setState({ render: false }, () => {
            this.setState({ render: true }, () => {
                this.started = false;
            });
        })
    }

    componentWillUnmount() {
        this.started = false;
        this.path = null;
    }

    render() {
        if (this.props.path !== this.path) {
            this.path = this.props.path;

            if (this.props.reload) return null;
        }
        if (!this.state.render) return null;

        return (
            <React.Fragment>
                {this.props.children}
            </React.Fragment>
        );
    }
}