import React from 'react';
import '../assets/css/login.scss';

import { createValidationContext } from 'v4react';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import DataTable from '../components/dataTable';
import LoadingButton from '../components/loadingButton';
import { stateService, authService, routingService } from '../services/imports';

import logo from '../assets/images/logoFull.png';

export default class LogInPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            temporaryToken: null,

            companies: [],
            selected: null,
            columns: [
                { key: "id", identity: true },
                { key: "name", name: "დასახელება" }
            ]
        };

        this.validation = createValidationContext({
            userName: ['', 'required'],
            password: ['', 'required']
        }, { update: this });
    }

    login = (e) => {
        e.preventDefault();
        if (!this.validation.setDirty().isValid()) return;

        const model = this.validation.model;
        stateService.event("button_login", true);

        authService.login(model.userName, model.password)
            .pipe(switchMap(data => {
                let { access_token, refresh_token, temporaryToken, companies } = data;
                if (temporaryToken) return of({ temporaryToken, companies });

                authService.setToken(access_token, refresh_token);
                return authService.getUserInfo();
            }))
            .subscribe({
                next: data => {
                    stateService.event("button_login", false);

                    if (data.temporaryToken) {
                        this.setState({ temporaryToken: data.temporaryToken, companies: data.companies || [] });
                        return;
                    }

                    authService.setUser(data);
                    routingService.push('home');
                },
                error: err => {
                    stateService.event("button_login", false);
                }
            });
    }

    tokenLogin = e => {
        e.preventDefault();
        const { temporaryToken, selected } = this.state;

        authService.tokenLogin(temporaryToken, selected.id)
            .pipe(switchMap(data => {
                let { access_token, refresh_token } = data;

                authService.setToken(access_token, refresh_token);
                return authService.getUserInfo();
            }))
            .subscribe({
                next: data => {
                    authService.setUser(data);
                    routingService.push('home');
                }
            });
    }

    componentDidMount(){
        if(authService.isAuthenticated()){
            routingService.push('home');
        }
    }

    render() {
        const { temporaryToken, companies, selected, columns } = this.state;
        const { userName, password } = this.validation.controls;

        return <div className="login-container">
            <h3 className="text-center">სისტემაში შესვლა</h3>

            <div className="text-center mt-4">
                <img className="login-img" src={logo} />
            </div>

            <form className="mt-4" onSubmit={temporaryToken ? this.tokenLogin : this.login} noValidate>
                <div className="form-group">
                    <label htmlFor="userName">მომხმარებელი</label>
                    <input type="text" id="userName" name="userName" ref={userName.ref} readOnly={!!temporaryToken}
                        value={userName.value || ''} onChange={e => { userName.value = e.target.value; }}
                        className={`form-control ${userName.err() ? 'invalid' : ''}`} required />
                    <div className="invalid-feedback">სავალდებულოა</div>
                </div>

                <div className="form-group">
                    <label htmlFor="password">პაროლი</label>
                    <input type="password" id="password" name="password" ref={password.ref} readOnly={!!temporaryToken}
                        value={password.value || ''} onChange={e => { password.value = e.target.value; }}
                        className={`form-control ${password.err() ? 'invalid' : ''}`} required />
                    <div className="invalid-feedback">სავალდებულოა</div>
                </div>

                {
                    temporaryToken &&
                    <div className="form-group">
                        <DataTable className="table table-sm table-bordered" selected={selected}
                            onSelectedChange={selected => { this.setState({ selected }); }}
                            source={companies} columns={columns} disablePaging disableFilter height="150px" />
                    </div>
                }

                <div className="text-center">
                    <LoadingButton type="submit" className="btn btn-success"
                        loading="button_login" disabled={temporaryToken && !selected}>შესვლა</LoadingButton>
                </div>
            </form>
        </div >;
    }
}
