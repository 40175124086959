import axios from 'axios';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

import htmlHelperService from './htmlHelperService';
import authService from './authService';
import { Store } from 'react-notifications-component';

class CommonService {
    guid() {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
            s4() + '-' + s4() + s4() + s4();
    }

    formatNumber(number, props = { digits: 2, trim: undefined, separator: undefined }) {
        if (!number && number !== 0) return "";
        if (typeof number == 'string') number = Number(number);

        if (typeof props === 'number') props = { digits: props };
        props = props || {};
        if (!props.digits && props.digits !== 0) props.digits = 2;

        if (props?.trim) {
            const numberStr = number.toString();
            const dotIndex = numberStr.indexOf('.');

            let digitsStr = dotIndex === -1 ? "" : numberStr.substring(dotIndex + 1);
            while (digitsStr && digitsStr[digitsStr.length - 1] === "0")
                digitsStr = digitsStr.slice(0, digitsStr.length - 1);

            let digitLength = digitsStr.length;
            if (digitLength < props.digits) props.digits = digitLength;
        }

        const value = number.toFixed(props.digits);
        if (props?.separator) {
            return value.replace(/\B(?=(\d{3})+(?!\d))/g, props.separator)
        }

        return value;
    }

    //register common interseptors for normalzing response
    registerCommonInterceptor() {
        let requestInterceptor = axios.interceptors.request.use(config => {
            config.headers = config.headers || {};
            if (config.enableLoader) htmlHelperService.loaderVisible(true);

            return config;
        });

        let responseInterceptor = axios.interceptors.response.use(
            response => {
                if (response.config.enableLoader) htmlHelperService.loaderVisible(false);
                if (response.config.fullResponse) return Promise.resolve(response);

                let data = response.data;
                return Promise.resolve(data);
            },
            error => {
                if (error.config.enableLoader) htmlHelperService.loaderVisible(false);
                if (error.response?.status !== 401 && !error.config.skipNotify) {
                    let errorMessage = error.response?.status === 403 ? 'არ გაქვთ ოპერაციის შესრულების უფლება!' : 'მოხდა შეცდომა!';
                    const errorData = error.response?.data;

                    if (error.response?.status === 400 && errorData) {
                        if (errorData.Message) errorMessage = errorData.Message;
                        else if (errorData.errors) {
                            for (let prop in errorData.errors) {
                                if (!errorData.errors[prop].length) continue;
                                errorMessage = errorData.errors[prop][0];
                                break;
                            }
                        }
                    }

                    this.showMessage('danger', errorMessage);
                }

                return Promise.reject(error);
            }
        );

        return {
            unsubscribe: () => {
                axios.interceptors.request.eject(requestInterceptor);
                axios.interceptors.response.eject(responseInterceptor);
            }
        };
    }

    showMessage(type, message, title = '') {
        Store.addNotification({
            title,
            message,
            type,
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated animate__fadeIn"],
            animationOut: ["animate__animated animate__fadeOut"],
            dismiss: { duration: 3000 }
        })
    }

    createTree(source, childrenProp = "children", parentProp = "parent", parrentIdKey = "parentId", idKey = "id") {
        let list = [];
        let parrentIdFn = parrentIdKey, idFn = idKey;

        if (typeof parrentIdKey == "string") parrentIdFn = x => x[parrentIdKey];
        if (typeof idKey == "string") idFn = x => x[idKey];

        let fn = parent => {
            if (!parent[childrenProp]) parent[childrenProp] = [];

            for (let item of source) {
                if (parrentIdFn(item) !== idFn(parent)) continue;
                item = { ...item };

                parent[childrenProp].push(item);
                if (parentProp) item[parentProp] = parent;
                fn(item);
            }
        }

        for (let item of source) {
            if (parrentIdFn(item)) continue;
            item = { ...item };
            if (parentProp) item[parentProp] = null;

            list.push(item);
            fn(item);
        }

        return list;
    }

    destructTree(source, childrenProp = "children", parentProp = "parent", remove = true) {
        let list = [];

        let fn = item => {
            item = { ...item };

            list.push(item);
            for (let child of (item[childrenProp] || [])) fn(child);

            if (remove) {
                delete item[childrenProp];
                delete item[parentProp];
            }
        }

        for (let item of source) fn(item);
        return list;
    }

    searchTree(source, findKey, selected,
        idKey = "id", childrenProp = "children", parentProp = "parent") {
        let found = null;
        let data = this.destructTree(source, childrenProp, parentProp, false);

        let findFn = findKey, idFn = idKey;
        if (typeof findKey == "string") findFn = x => (x.name.indexOf(findKey) !== -1);
        if (typeof idKey == "string") idFn = x => x[idKey];

        let selectedIndex = selected ? data.findIndex(x => idFn(x) === idFn(selected)) : -1;
        if (selectedIndex != -1) found = data.filter((x, i) => i > selectedIndex).find(findFn);
        if (!found) found = data.find(findFn);

        return found;
    }

    detectChanges(source, target, equalsKey = "id", idKey = "id", updates = false) {
        if (!source.length && !target.length) return [];

        let equalsFn = equalsKey, idFn = idKey;
        if (typeof equalsKey == "string") equalsFn = (x, y) => x[equalsKey] === y[equalsKey];
        if (typeof idKey == "string") idFn = x => x[idKey];

        let diff = target.filter(x => !source.find(y => equalsFn(x, y)))
            .map(x => {
                let result = {};
                result.id = idFn(x);

                if (updates && source.find(t => idFn(t) == result.id)) result.update = true;
                else result.add = true;

                return result;
            });
        diff = [...diff, ...source.filter(x => {
            let found = target.find(y => equalsFn(x, y));
            if (found && updates && target.find(t => idFn(t) == idFn(found))) return false;
            return !found;
        }).map(x => ({ id: idFn(x), removed: true }))];

        return diff;
    }

    sort(arr, sortKey = "sortId", thenkey = "id", desc = false) {
        let sortFn = sortKey, thenFn = thenkey;
        if (typeof sortKey == "string") sortFn = x => x[sortKey];
        if (typeof thenkey == "string") thenFn = x => x[thenkey];

        let exists = arr.filter(x => sortFn(x) !== null && sortFn(x) !== undefined);
        let notExists = arr.filter(x => sortFn(x) === null || sortFn(x) === undefined);

        exists.sort((a, b) => {
            let compare = sortFn(a) - sortFn(b);
            if (compare && desc) compare *= -1;

            if (!compare && thenFn) compare = thenFn(a) - thenFn(b);
            if (compare && desc) compare *= -1;

            return compare;
        });

        if (thenFn)
            notExists.sort((a, b) => {
                let compare = thenFn(a) - thenFn(b);
                if (compare && desc) compare *= -1;

                return compare;
            });

        return [...exists, ...notExists];
    }

    number(e) {
        if (e === null || e === undefined) return undefined;
        if (typeof e === "string") return Number(e);

        if (typeof e === "object" && e.target) {
            if (!e.target.value) return undefined;
            return Number(e.target.value);
        }

        return e;
    }

    boolean(e) {
        if (e === null || e === undefined) return undefined;
        if (typeof e === "string") return e === 'true';

        if (typeof e === "object" && e.target) {
            if (!e.target.value) return undefined;
            return e.target.value === 'true';
        }

        return e;
    }

    pollRequests(fn, time, maxSize = 1, onlyAuthorized = true) {
        let observable = fn;
        if (typeof observable == "function") {
            observable = new Observable(subscriber => {
                fn().subscribe({
                    next: data => {
                        subscriber.next(data);
                        subscriber.complete();
                    },
                    error: err => {
                        subscriber.error(err);
                    }
                });
            });
        }

        let subscriptions = [];
        let pullSize = 1;
        let subscriptionLength = maxSize > 250 ? (maxSize * 2) : 500;

        return {
            subscribe: fn => {
                let onFinished = finalize(() => {
                    pullSize--;
                    if (subscriptions.length > subscriptionLength) subscriptions = subscriptions.filter(x => !x.closed);
                });

                subscriptions.push(observable.pipe(onFinished).subscribe(fn));
                let interval = setInterval(() => {
                    if (onlyAuthorized && (authService.refreshStarted || !authService.isAuthenticated())) return;
                    if (maxSize && pullSize > maxSize) return;

                    pullSize++;
                    subscriptions.push(observable.pipe(onFinished).subscribe(fn));
                }, time);

                return {
                    unsubscribe() {
                        clearInterval(interval);
                        for (let sub of subscriptions)
                            if (!sub.closed) sub.unsubscribe();
                    }
                };
            }
        };
    }
}

export default new CommonService();