import axios from 'axios';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';

import stateService from './stateService';
import dateHelperService from './dateHelperService';
import { states, stateEvents } from '../models/imports';

class AuthService {
    //set token and refresh token
    setToken(token, refreshToken) {
        localStorage.setItem('token', token);

        if (refreshToken !== undefined) {
            localStorage.setItem('refreshToken', refreshToken);
        }
    }

    //get token
    getToken() {
        return localStorage.getItem("token");
    }

    //get refresh token
    getRefreshToken() {
        return localStorage.getItem("refreshToken");;
    }

    //remove token and refresh token
    removeToken() {
        localStorage.removeItem('token')
        localStorage.removeItem('refreshToken')
    }

    //is authenticated
    isAuthenticated() {
        let token = this.getToken();
        let refreshToken = this.getRefreshToken();

        return token != null || refreshToken != null;
    }

    //register auth interseptors
    //disables when anonymous is passed in config
    registerAuthInterceptor() {
        const setAuthToken = (config) => {
            config.headers = config.headers || {};
            config.headers['Authorization'] = "Bearer " + this.getToken();
        }

        const waitForRefresh = (config) => {
            return new Promise((resolve) => {
                let interval = setInterval(() => {
                    if (this.refreshStarted) return;

                    clearInterval(interval);
                    resolve(config);
                }, 500);
            });
        }

        //add auth header
        let requestInterceptor = axios.interceptors.request.use((config) => {
            if (this.isAuthenticated() && !config.anonymous) {
                //if refreshStarted wait
                if (this.refreshStarted && !config.skipRefresh) {
                    return waitForRefresh(config).then(config => {
                        if (!this.getToken()) return Promise.reject({ status: 401 });
                        setAuthToken(config);
                        return Promise.resolve(config);
                    });
                }

                setAuthToken(config);
            }
            return config;
        });

        // if unauthorized refetch
        let responseInterceptor = axios.interceptors.response.use(
            response => response,
            error => {
                error.response = error.response || {};

                // Reject promise if usual error
                if (error.response.status !== 401 || error.config.anonymous || error.config.skipRefresh) {
                    return Promise.reject(error);
                }
                const originalRequest = error.config;
                //if refresh already started wait and retry with new token
                if (this.refreshStarted) {
                    return waitForRefresh().then(_ => {
                        if (!this.getToken()) return Promise.reject({ status: 401 });
                        setAuthToken(originalRequest);
                        return axios(originalRequest);
                    });
                }

                //refresh token
                this.refreshStarted = true;
                return axios.post(`${process.env.REACT_APP_API_URL}Auth/refreshtoken`,
                    { refresh_token: this.getRefreshToken() }, { fullResponse: true, anonymous: true, skipNotify: true, enableLoader: true }).then(response => {
                        if (!response.data.access_token || !response.data.refresh_token) throw response;
                        this.setToken(response.data.access_token, response.data.refresh_token);
                        this.refreshStarted = false;

                        setAuthToken(originalRequest);
                        return axios(originalRequest);
                    }).catch(err => {
                        this.logout();
                        this.refreshStarted = false;

                        return Promise.reject(err);
                    });
            });

        return {
            unsubscribe: () => {
                axios.interceptors.request.eject(requestInterceptor);
                axios.interceptors.response.eject(responseInterceptor);
            }
        };
    }

    login(username, password) {
        const promise = axios.post(`${process.env.REACT_APP_API_URL}Auth/token`, { username, password });
        return from(promise);
    }

    tokenLogin(temporaryToken, companyId) {
        const promise = axios.post(`${process.env.REACT_APP_API_URL}Auth/tokenlogin`, { temporaryToken, companyId }, { enableLoader: true });
        return from(promise);
    }

    logout() {
        this.removeToken();
        stateService.event(stateEvents.signOut);
    }

    changePassword(oldPassword, newPassword) {
        const promise = axios.post(`${process.env.REACT_APP_API_URL}Auth/changepassword`, { oldPassword, newPassword });
        return from(promise);
    }

    getUserInfo() {
        const promise = axios.get(`${process.env.REACT_APP_API_URL}Auth/userinfo`, { enableLoader: true });
        return from(promise).pipe(map(data => {
            data.birthDate = data.birthDate && dateHelperService.parse(data.birthDate);
            return data;
        }));
    }

    saveUserInfo(model) {
        const promise = axios.put(`${process.env.REACT_APP_API_URL}Auth/userinfo`, model);

        return from(promise).pipe(map(data => {
            data.birthDate = data.birthDate && dateHelperService.parse(data.birthDate);           
            return data;
        }));
    }

    get user() {
        return stateService.getState(states.userInfo);
    }

    setUser(user) {
        if (!user) stateService.clearState(states.userInfo);
        else stateService.setState(states.userInfo, user);
    }

    hasPermission(claim) {
        const user = this.user.value;
        if (!user.claims) return false;

        return !!user.claims.find(x => x.name === claim);
    }
}

export default new AuthService();