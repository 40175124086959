import React from 'react';
import { createValidationContext } from 'v4react';
import { Collapse } from 'reactstrap';

import Modal from '../components/modal/modal';
import LoadingButton from '../components/loadingButton';
import ImagePicker from '../components/imagePicker';

import { authService, stateService, commonService } from '../services/imports';
import { defaults, claims } from '../models/imports';

export default class ProfilePage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            user: {},
            collapse: {},

            showPasswordModal: false,
            showOld: false,
            showNew: false,
            showRepeate: false
        };

        this.passwordValidation = createValidationContext({
            oldPassword: ['', 'required'],
            newPassword: ['', 'required', { name: 'pattern', value: defaults.passwordRegex }],
            repeatePassword: ['', 'required', { name: 'equals', value: () => this.passwordValidation.controls.newPassword.value }]
        }, { update: this });

        this.infoValidation = createValidationContext({
            email: ['', 'required', 'email'],
            phone: ['', 'required'],
            photoImage: [''],
            imageChanged: [false]
        }, { update: this });

        this.subscruptions = [];
        this.permissions = {};
    }

    onCollapse = prop => {
        this.setState(state => {
            let collapse = {};
            collapse[prop] = !state.collapse[prop];

            return { collapse: { ...state.collapse, ...collapse } };
        });
    }

    showPasswordChangeModal = () => {
        this.passwordValidation.model = { oldPassword: '', newPassword: '', repeatePassword: '' };
        this.passwordValidation.setPristine();

        this.setState({ showPasswordModal: true, showOld: false, showNew: false, showRepeate: false });
    }

    changePassword = () => {
        if (!this.passwordValidation.setDirty().isValid()) return;

        const { oldPassword, newPassword } = this.passwordValidation.model;
        stateService.event('button_changepassword', true);

        authService.changePassword(oldPassword, newPassword).subscribe({
            next: data => {
                commonService.showMessage('success', 'პაროლი წარმატებით შეიცვალა');
                this.setState({ showPasswordModal: false });
                stateService.event('button_changepassword', false);
            },
            error: err => { stateService.event('button_changepassword', false); }
        });
    }

    saveInfo = () => {
        if (!this.infoValidation.setDirty().isValid()) return;

        const { email, phone, photoImage, imageChanged } = this.infoValidation.model;
        stateService.event('button_saveaccountinfo', true);

        authService.saveUserInfo({ email, phone, photoImage: imageChanged ? photoImage : null }).subscribe({
            next: data => {
                stateService.event('button_saveaccountinfo', false);
                this.infoValidation.setPristine();

                commonService.showMessage('success', defaults.saveSuccess);
                authService.setUser(data);
            },
            error: () => {
                stateService.event('button_saveaccountinfo', false);
            }
        });
    }

    componentDidMount() {
        this.permissions = {
            updateInfo: authService.hasPermission(claims.Consumer),
            consumer: authService.hasPermission(claims.Consumer)
        };
        this.subscruptions.push(authService.user.subscribe(data => {
            const { email, phone, photoImage, ...user } = data;

            this.infoValidation.model = { email, phone, photoImage, imageChanged: false };
            this.setState({ user });
        }));
    }

    componentWillUnmount() {
        for (let sub of this.subscruptions)
            sub.unsubscribe();
    }

    render() {
        const { user, collapse, showPasswordModal, showOld, showNew, showRepeate } = this.state;
        const { oldPassword, newPassword, repeatePassword } = this.passwordValidation.controls;
        const closePasswordModal = () => { this.setState({ showPasswordModal: false }); }

        const { email, phone, photoImage, imageChanged } = this.infoValidation.controls;
        const viewMode = !this.permissions.updateInfo;
        const showImage = !!this.permissions.consumer;

        return <React.Fragment>
            <div className="card">
                <div className="card-header card-header-grey py-2 clickable"
                    onClick={e => { this.onCollapse('userCollapsed'); }}>
                    ავტორიზაციის პარამეტრები
                </div>
                <Collapse isOpen={!collapse.userCollapsed}>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-lg">
                                <div className="row form-group">
                                    <label className="form-label col-xl-4 col-lg-5 pr-0">მომხმარებელი</label>
                                    <div className="col-xl-8 col-lg-7">
                                        <input type="text" className="form-control form-control-sm" readOnly
                                            value={user.username || ''} />
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg">
                                <div className="row form-group">
                                    <label className="form-label col-xl-4 col-lg-5 pr-0">პაროლი</label>
                                    <div className="col-xl-8 col-lg-7">
                                        <div className="input-group input-group-sm">
                                            <input type="password" className="form-control form-control-sm" readOnly value="********" />
                                            <div className="input-group-append clickable" onClick={this.showPasswordChangeModal}>
                                                <span className="input-group-text bg-light"><i className="fa fa-edit colored"></i></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Collapse>
            </div >

            <div className="card mt-4">
                <div className="card-header card-header-grey py-2 clickable"
                    onClick={e => { this.onCollapse('userInfoCollapsed'); }}>
                    პირადი მონაცემები
                </div>
                <Collapse isOpen={!collapse.userInfoCollapsed}>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="row form-group">
                                    <label className="form-label col-xl-4 col-lg-5 pr-0">პირადი ნომერი</label>
                                    <div className="col-xl-8 col-lg-7">
                                        <input type="text" className="form-control form-control-sm" readOnly
                                            value={user.personalNumber || ''} />
                                    </div>
                                </div>

                                <div className="row form-group">
                                    <label className="form-label col-xl-4 col-lg-5 pr-0">სახელი</label>
                                    <div className="col-xl-8 col-lg-7">
                                        <input type="text" className="form-control form-control-sm" readOnly
                                            value={user.firstName || ''} />
                                    </div>
                                </div>

                                <div className="row form-group">
                                    <label className="form-label col-xl-4 col-lg-5 pr-0">გვარი</label>
                                    <div className="col-xl-8 col-lg-7">
                                        <input type="text" className="form-control form-control-sm" readOnly
                                            value={user.lastName || ''} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="row form-group">
                                    <label className="form-label col-xl-4 col-lg-5 pr-0">ტელეფონი</label>
                                    <div className="col-xl-8 col-lg-7">
                                        <input type="text" ref={phone.ref}
                                            className={`form-control form-control-sm ${phone.err() ? 'invalid' : ''}`}
                                            value={phone.value || ''} onChange={e => { phone.value = e.target.value; }}
                                            name="phone" autoComplete="off" readOnly={viewMode} required />
                                        <div className="invalid-feedback">სავალდებულოა</div>
                                    </div>
                                </div>

                                <div className="row form-group">
                                    <label className="form-label col-xl-4 col-lg-5 pr-0">ელ. ფოსტა</label>
                                    <div className="col-xl-8 col-lg-7">
                                        <input type="text" ref={email.ref}
                                            className={`form-control form-control-sm ${email.err() ? 'invalid' : ''}`}
                                            value={email.value || ''} onChange={e => { email.value = e.target.value; }}
                                            name="email" autoComplete="off" readOnly={viewMode} required />
                                        <div className="invalid-feedback">
                                            {email.err('required') ? 'სავალდებულოა' : 'ფორმატი არავალიდურია'}
                                        </div>
                                    </div>
                                </div>

                                <div className="row form-group">
                                    <label className="form-label col-xl-4 col-lg-5 pr-0">მისამართი</label>
                                    <div className="col-xl-8 col-lg-7">
                                        <input type="text" className="form-control form-control-sm" readOnly
                                            value={user.livingAddress || ''} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            showImage &&
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="row">
                                        <label className="form-label col-xl-4 col-lg-5 pr-0">ფოტო</label>
                                        <div className="col-xl-8 col-lg-7">
                                            <ImagePicker disabled={!!photoImage.value && !imageChanged.value}
                                                convertToBase64 maxWidth="225" maxHeight="225"
                                                src={photoImage.value || ''}
                                                onChange={file => { photoImage.value = file.value; imageChanged.value = true; }}
                                                onRemove={e => { photoImage.value = ''; imageChanged.value = false; }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </Collapse>
            </div>
            {
                !viewMode &&
                <div className="form-group mt-3">
                    <LoadingButton className="btn btn-sm btn-primary px-3" loading="button_saveaccountinfo"
                        onClick={this.saveInfo}>შენახვა</LoadingButton>
                </div>
            }

            <Modal show={showPasswordModal} onClose={closePasswordModal} title="პაროლის ცვლილება">
                <div className="modal-body">
                    <div className="form-group">
                        <label>ძველი პაროლი</label>
                        <div className="input-group input-group-sm">
                            <input type={showOld ? 'text' : 'password'}
                                ref={oldPassword.ref} name="oldPassword" required
                                className={`form-control form-control-sm ${oldPassword.err() ? 'invalid' : ''}`}
                                value={oldPassword.value || ''}
                                onChange={e => { oldPassword.value = e.target.value; }} />
                            <div className="input-group-append clickable"
                                onClick={e => { this.setState(state => ({ showOld: !state.showOld })) }}>
                                <span className="input-group-text bg-light">
                                    <i className={`fa fa-eye${showOld ? '-slash' : ''}`}></i>
                                </span>
                            </div>
                        </div>
                        {
                            oldPassword.err('required') &&
                            <div className="invalid-feedback d-block">სავალდებულოა</div>
                        }
                        {
                            oldPassword.err('pattern') &&
                            <div className="invalid-feedback d-block">არავალიდურია</div>
                        }
                    </div>

                    <div className="form-group">
                        <label>ახალი პაროლი</label>
                        <div className="input-group input-group-sm">
                            <input type={showNew ? 'text' : 'password'}
                                ref={newPassword.ref} name="newPassword" required
                                className={`form-control form-control-sm ${newPassword.err() ? 'invalid' : ''}`}
                                value={newPassword.value || ''}
                                onChange={e => { newPassword.value = e.target.value; this.passwordValidation.validate(); }} />
                            <div className="input-group-append clickable"
                                onClick={e => { this.setState(state => ({ showNew: !state.showNew })) }}>
                                <span className="input-group-text bg-light">
                                    <i className={`fa fa-eye${showNew ? '-slash' : ''}`}></i>
                                </span>
                            </div>
                        </div>
                        {
                            newPassword.err('required') &&
                            <div className="invalid-feedback d-block"> სავალდებულოა</div >
                        }
                        {
                            newPassword.err('pattern') &&
                            <div className="invalid-feedback d-block"> არავალიდურია</div >
                        }
                    </div >

                    <div className="form-group">
                        <label>გაიმეორეთ პაროლი</label>
                        <div className="input-group input-group-sm">
                            <input type={showRepeate ? 'text' : 'password'}
                                ref={repeatePassword.ref} name="repeatPassword" required
                                className={`form-control form-control-sm ${repeatePassword.err() ? 'invalid' : ''}`}
                                value={repeatePassword.value || ''}
                                onChange={e => { repeatePassword.value = e.target.value; }} />
                            <div className="input-group-append clickable"
                                onClick={e => { this.setState(state => ({ showRepeate: !state.showRepeate })) }}>
                                <span className="input-group-text bg-light">
                                    <i className={`fa fa-eye${showRepeate ? '-slash' : ''}`}></i>
                                </span>
                            </div>
                        </div >
                        {
                            repeatePassword.err('required') &&
                            <div className="invalid-feedback d-block"> სავალდებულოა</div >
                        }
                        {
                            repeatePassword.err('equals') &&
                            <div className="invalid-feedback d-block"> არავალიდურია</div >
                        }
                    </div >
                </div >
                <div className="modal-footer">
                    <LoadingButton className="btn btn-sm btn-success px-3" loading="button_changepassword"
                        onClick={this.changePassword}>შეცვლა</LoadingButton>
                    <button className="btn btn-sm btn-secondary" onClick={closePasswordModal}>დახურვა</button>
                </div>
            </Modal >
        </React.Fragment >
    }
}