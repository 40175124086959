import React from 'react';

import { claims } from '../../models/imports';
import { authService } from '../../services/imports';
import statisticService from './statisticsService';

export default class HomePage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeTab: 0,

            keys: [],
            statistics: []
        };

        this.subscriptions = [];
        this.permissions = {};
    }

    componentDidMount() {
        this.permissions = {
            statistics: authService.hasPermission(claims.Central_Statistics)
        };

        let activeTab = 0;
        if (this.permissions.statistics) activeTab = 1;

        this.setState({ activeTab });

        this.subscriptions.push(statisticService.getStatistics().subscribe(data => {
            this.setState({ keys: data.keys, statistics: data.results })
        }));
    }

    componentWillUnmount() {
        for (let sub of this.subscriptions)
            sub.unsubscribe();
    }

    render() {
        const { activeTab, keys, statistics } = this.state;

        return <React.Fragment>
            <ul className="nav nav-tabs">
                {
                    this.permissions.statistics &&
                    <li className="nav-item" onClick={e => this.setState({ activeTab: 1 })}>
                        <a className={`nav-link clickable ${activeTab === 1 ? 'active' : ''}`}>სტატისტიკა</a>
                    </li >
                }
            </ul >

            {
                activeTab == 1 &&
                <div className="mt-5">
                    <table className='table table-striped table-bordered'>
                        <thead>
                            <tr>
                                <th>აღწერა</th>
                                {
                                    keys.map(item => (
                                        <th key={item.name}>{item.description}</th>
                                    ))
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                statistics.map(item => (
                                    <tr key={item.id}>
                                        <td>{item.description}</td>
                                        {
                                            keys.map(key => (
                                                <td key={key.name}>{item[key.name]}</td>
                                            ))
                                        }
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div >
            }
        </React.Fragment>;
    }
}
