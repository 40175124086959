import { matchPath } from 'react-router-dom';

class RoutingService {
    constructor() {
        let base = document.getElementsByTagName("base");
        base = base.length ? base[0] : null;
        let baseUrl = base ? base.getAttribute("href") : '';


        this.routes = {
            home: `${baseUrl}`,
            login: `${baseUrl}login`,
            profile: `${baseUrl}profile/`,
            users: `${baseUrl}users/`,
            settings: `${baseUrl}settings/`,

            companies: `${baseUrl}companies/`,
            invoices: `${baseUrl}invoices/`,
            payments: `${baseUrl}payments/`,
            reports: `${baseUrl}reports/`
        };

        this._events = [];
    }

    initRouter(history) {
        this._history = history;
    }

    routeChanged() {
        const state = this._history.state;

        if (this._key !== state.location.key) {
            this._key = state.location.key
            const pathname = state.location.pathname;

            this._params = {};
            for (const prop in this.routes) {
                const match = matchPath(this.routes[prop], pathname);
                if (!match) continue;

                this._params = match.params;
                break;
            }

            for (const event of this._events) {
                event();
            }
        }

        return this.history;
    }

    //listen route changed event
    listen(imediateExec = true) {
        let unlistened = false;
        return {
            subscribe: (fn) => {
                let exec = () => {
                    setTimeout(() => {
                        if (unlistened) return;
                        fn();
                    }, 20);
                }
                if (imediateExec) exec();
                this._events.push(exec);
                return {
                    unsubscribe: () => {
                        unlistened = true;
                        const index = this._events.indexOf(exec);
                        if (index !== -1) this._events.splice(exec);
                    }
                };
            }
        }
    }

    //get route path from name and optional params
    routePath(name, params) {
        let route = this.routes[name];
        if (!route) return '';

        if (params) {
            for (let prop in params) {
                route = route.replace(`:${prop}`, params[prop]);
            }
        }

        return route;
    }

    //get route name from path
    routeName(path) {
        if (!path) path = this.history.location.pathname;
        if (!path.endsWith('/')) path += '/';

        for (let routeName in this.routes) {
            let route = this.routes[routeName];

            for (let param in this.params) {
                route = route.replace(`:${param}`, this.params[param]);
            }

            if (route == path) return routeName;
        }
        return null;
    }

    //is current route equal to name
    is(name) {
        let routName = this.routeName();
        return routName == name;
    }

    //refresh current route
    refresh() {
        this.history.replace(window.location.pathname + window.location.search);
    }

    //redirect to route
    push(name, props) {
        props = props || {};

        let path = this.routePath(name, props.params);
        if (props.query) path += this.toQuery(props.query);

        this.history.push(path);
    }

    //replace route
    replace(name, props) {
        props = props || {};

        let path = this.routePath(name, props.params);
        if (props.query) path += this.toQuery(props.query);

        this.history.replace(path);
    }

    //pushes if different route or replaces if current
    navigate(name, props) {
        props = props || {};
        let routePath = this.routePath(name, props.params);

        if (routePath == this.history.location.pathname) this.replace(name, props);
        else this.push(name, props);
    }

    //get query object
    get query() {
        if (!window.location.search) return {};
        let search = window.location.search.substring(1);
        return JSON.parse('{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}', function (key, value) { return key === "" ? value : decodeURIComponent(value) });
    }

    //set query
    set query(params) {
        let query = this.toQuery(params);
        if (!query) return;

        let path = this.history.location.pathname;
        this.history.replace(path + (query || ''));
    }

    //get query string
    toQuery(query) {
        if (!query) return null;

        if (typeof query == 'string') {
            if (query[0] != '?') query = '?' + query;
        }
        else {
            let queryStr = '?';
            for (let prop in query) {
                if (query[prop] === undefined || query[prop] === null) continue;

                if (queryStr != '?') queryStr += '&';
                queryStr += `${prop}=${encodeURIComponent(query[prop])}`;
            }

            query = queryStr;
        }
        return query;
    }

    get params() {
        return this._params;
    }

    get history() {
        const history = this._history;

        return {
            location: history.state.location,
            push: to => history.navigate(to),
            replace: to => history.navigate(to, { replace: true })
        };
    }
}

export default new RoutingService();