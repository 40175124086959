import React from 'react';
import { stateService } from '../services/imports';

export default class LoadingButton extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false
        };

        this.button = React.createRef();
        this.clickEvent = null;
        this.subscription = null;
    }

    onClick = (e) => {
        if (this.clickEvent) return;

        this.clickEvent = setTimeout(() => {
            this.clickEvent = null;
        }, 200);

        if (this.props.onClick) this.props.onClick(e);
    }

    setLoading = isLoading => {
        if (!this.button.current) return;

        this.button.current.disabled = isLoading;
        this.setState({ loading: isLoading });
    }

    componentDidUpdate(prevProps) {
        if (this.props.loading === prevProps.loading && typeof this.props.loading !== "boolean") return;

        if (this.state.loading !== this.props.loading) {
            this.setLoading(this.props.loading);
        }
    }

    componentDidMount() {
        if (typeof this.props.loading != "string") return;

        this.subscription = stateService.onEvent(this.props.loading).subscribe(loading => {
            this.setLoading(loading);
        });
    }

    componentWillUnmount() {
        if (this.subscription) this.subscription.unsubscribe();
    }

    render() {
        const { loading: ldng, className, onClick, ...rest } = this.props;
        const { loading } = this.state;

        return <button ref={this.button} {...rest} className={`${className}${loading ? ' loading-before' : ''}`} onClick={this.onClick}></button>;
    }
}