import { Observable } from 'rxjs';

class HtmlHelperService {
    static loaderVisibilityCount = 0;

    //read from file using FileReader
    readFromFile(file, callback, options) {
        if (!FileReader) {
            throw "browser can't support FileReader";
        }
        options = options || { readAsDataURL: true };

        let fr = new FileReader();
        fr.onload = function () {
            callback(fr.result);
        }
        if (options.readAsBinaryString)
            fr.readAsBinaryString(file);
        else if (options.readAsText)
            fr.readAsText(file);
        else if (options.readAsDataURL)
            fr.readAsDataURL(file);
        else if (options.readAsArrayBuffer)
            fr.readAsArrayBuffer(file);
        else throw "unknown type";

        return fr;
    }

    //convert file data to attachment
    base64ToAttachment(data, fileName) {
        let split = data.substring(5).split(";base64,");
        let contentType = split[0];
        let base64 = split[1];

        return {
            fileName: fileName,
            contentType: contentType,
            source: base64
        };
    }

    //download file as octet-stream
    downloadFile(name, content) {
        let a = document.createElement("a");
        a.href = `data:application/octet-stream;base64,${content}`;
        a.download = name;
        a.target = "_blank";

        document.body.appendChild(a);
        a.click();
        a.remove();
    }

    //copy text to clipboard
    copyToClipboard(text) {
        let input = document.createElement("input");
        input.value = text;
        document.body.appendChild(input);
        try {
            input.select();
            document.execCommand("Copy");
        }
        catch (e) {
            console.error(e);
        }
        finally {
            input.remove();
        }
    }

    onBlur(...elements) {
        elements = elements.filter(x => x);

        return new Observable(observer => {
            let isDescendant = e => {
                if (e == null || e == window || e == document) return false;

                for (let element of elements)
                    if (e == element) return true;

                return isDescendant(e.parentNode);
            }

            let onClick = e => {
                if (isDescendant(e.target)) return;
                observer.next(e);
            }

            let timeout = setTimeout(() => { document.body.addEventListener('click', onClick); }, 0);
            return {
                unsubscribe: () => {
                    if (timeout) clearTimeout(timeout);
                    window.removeEventListener('click', onClick);
                }
            }
        });
    }

    //show Loader
    loaderVisible(visible) {
        let loader = document.getElementById('pageLoader');

        if (visible) {
            if (!HtmlHelperService.loaderVisibilityCount) {
                setTimeout(_ => {
                    if (HtmlHelperService.loaderVisibilityCount) loader.style.display = "flex";
                }, 400);
            }
            HtmlHelperService.loaderVisibilityCount++;
        }
        else {
            if (HtmlHelperService.loaderVisibilityCount > 0) HtmlHelperService.loaderVisibilityCount--;
            if (!HtmlHelperService.loaderVisibilityCount) loader.style.display = "none";
        }
    }
}

export default new HtmlHelperService();