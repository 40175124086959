import React from 'react';
import { Link } from 'react-router-dom';

import { routingService } from '../../services/imports';

export default class LinkComponent extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let { to, params, query, replace, ...props } = this.props;
        let path = routingService.routePath(to, params);
        let pathQuery = query ? routingService.toQuery(query) : '';
        replace = replace === undefined ? (path == routingService.history.location.pathname) : replace;

        return <Link to={path + pathQuery} replace={replace} {...props} />
    }
}