import axios from 'axios';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';

class StatisticService {
    getStatistics() {
        const promise = axios.get(`${process.env.REACT_APP_API_URL}Statistics/CentralStatistics`);
        return from(promise).pipe(map(data => {
            data = data.items || [];
            const maxValues = Math.max(...data.map(x => x.values?.length || 0));
            if (!maxValues) return { keys: [], results: [] };

            const keys = data.find(x => x.values?.length === maxValues).values.map(x => ({ name: x.key, description: x.description }));
            const results = [];

            data.forEach(x => {
                x.values = x.values || [];
                const item = {
                    id: x.key,
                    description: x.description
                };

                for (const key of keys) {
                    const match = x.values.find(x => x.key === key.name);
                    if (!match) continue;

                    item[key.name] = match.value;
                }

                results.push(item);
            });

            return { keys, results };
        }));
    }
}

export default new StatisticService();
