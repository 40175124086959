import React from "react";
import { fromEvent } from 'rxjs';

export default class ModalComponent extends React.Component {
    constructor(props) {
        super(props);

        this.isDraggable = null;

        this.topStart = null;
        this.leftStart = null;

        this.modalElement = null;
        this.element = null;

        this.subscriptions = [];
    }

    setElement = el => {
        this.element = el;
        if (!el) {
            for (let sub of this.subscriptions)
                sub.unsubscribe();

            this.subscriptions = [];
            return;
        }

        el.firstElementChild.style.position = 'relative';

        this.subscriptions.push(fromEvent(window.document, 'mousedown').subscribe(e => {
            if (e.button === 2 || !this.canDrage(e)) {
                return; // prevents right click drag
            }

            //enable dragging
            this.isDraggable = true;

            //store original position
            this.topStart = e.clientY - Number(this.element.firstElementChild.style.top.replace('px', ''));
            this.leftStart = e.clientX - Number(this.element.firstElementChild.style.left.replace('px', ''));
            e.preventDefault();
        }));

        this.subscriptions.push(fromEvent(window.document, 'mouseup').subscribe(e => {
            this.isDraggable = false;
        }));

        this.subscriptions.push(fromEvent(window.document, 'mousemove').subscribe(e => {
            if (!this.isDraggable) return;

            //on moving the mouse, reposition the modal
            this.element.firstElementChild.style.top = (e.clientY - this.topStart) + 'px';
            this.element.firstElementChild.style.left = (e.clientX - this.leftStart) + 'px';
        }));

        this.subscriptions.push(fromEvent(window.document, 'mouseleave').subscribe(e => {
            this.isDraggable = false;
        }));
    }

    canDrage = e => {
        if (!this.element) return false;

        const header = this.element.firstElementChild?.firstElementChild?.firstElementChild;
        if (!header || !header.classList.contains('modal-header')) return false;

        return e.target === header || e.target === header.querySelector('.modal-title');
    }

    componentWillUnmount() {
        for (let sub of this.subscriptions)
            sub.unsubscribe();
    }

    render() {
        const child = React.cloneElement(React.Children.only(this.props.children), { innerRef: this.setElement });
        return <React.Fragment>{child}</React.Fragment>;
    }
}