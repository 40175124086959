import React from 'react';
import { htmlHelperService } from '../services/imports';
import { defaults } from '../models/imports';

import defaultImage from '../assets/images/defaultImage.png';

export default class ImagePicker extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            src: ''
        };

        this.file = null;
    }

    chooseFiles = event => {
        this.file.click();
        event.preventDefault();
        event.stopPropagation();
    }

    removeFile = event => {
        event.preventDefault();
        event.stopPropagation();

        this.props.onRemove(event);
    }

    uploadFiles = event => {
        const files = event.target.files;
        if (!files.length) return;

        const file = files[0];
        const maxFileSize = this.props.maxSize || defaults.maxFileSize;
        if (file.size > maxFileSize) {
            this.props.onError && this.props.onError({ size: true, file });
            return;
        }

        const process = () => {
            if (!this.props.convertToBase64) {
                this.file.value = null;
                this.props.onChange(file);
                return;
            }

            htmlHelperService.readFromFile(file, data => {
                this.file.value = null;
                this.props.onChange({ name: file.name, value: data });
            });
        }

        if (!window.URL) {
            process();
            return;
        }

        const image = new Image();
        image.onload = () => {
            if (!image.width) {
                this.props.onError && this.props.onError({ type: true, file });
                return;
            }
            process();
        }
        image.onerror = () => {
            this.props.onError && this.props.onError({ type: true, file });
        }

        image.src = URL.createObjectURL(file);
    }

    get containerStyle() {
        const { width, height } = this.props;
        const style = {};

        if (width) style["width"] = width + "px";
        if (height) style["height"] = height + "px";

        return style;
    }

    get imageStyle() {
        const { width, height, maxWidth, maxHeight } = this.props;
        const style = {};

        if (maxWidth || width) style.maxWidth = (maxWidth || width) + "px";
        if (maxHeight || height) style.maxHeight = (maxHeight || height) + "px";

        return style;
    }

    setSrc = src => {
        if (src instanceof File) src = URL.createObjectURL(src);
        this.setState({ src });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.src === this.props.src) return;
        this.setSrc(this.props.src);
    }

    componentDidMount() {
        this.setSrc(this.props.src);
    }

    render() {
        let { accept, multiple, disabled, className } = this.props;
        const { src } = this.state;

        if (!accept) accept = "image/*";

        return <React.Fragment>
            <input ref={el => { this.file = el; }} type="file" className="d-none"
                accept={accept} multiple={multiple} disabled={disabled}
                onChange={this.uploadFiles} />

            <div className={className}>
                <span className="float-left" style={this.containerStyle}>
                    <img src={src || defaultImage}
                        className={`rounded ${disabled ? '' : 'float-left'}`} style={this.imageStyle} />
                </span>
                {
                    !disabled &&
                    <div className="imagepicker-buttons">
                        <span className="input-group-text bg-light clickable" onClick={this.chooseFiles}>
                            <i className="fa fa-search"></i>
                        </span>
                        <span className="input-group-text bg-light clickable" onClick={this.removeFile}>
                            <i className="fa fa-remove"></i>
                        </span >
                    </div >
                }
            </div >
        </React.Fragment >;
    }
}