import React from 'react';
import { routingService } from '../../services/imports';

//if no route matched redirects to default page
export class NoRouteMatched extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        routingService.replace(this.props.path || 'home');
    }

    render() {
        return null;
    }
}

export function noRouteMatched(path) {
    return <NoRouteMatched path={path} />
}