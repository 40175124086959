export const claims = {
    UserManagement: "UserManagement",
    Users: "Users",
    Users_Create: "Users.Create",
    Users_Edit: "Users.Edit",
    Users_EditRoles: "Users.EditRoles",
    Users_ResetPassword: "Users.ResetPassword",
    Users_ChangeStatus: "Users.ChangeStatus",
    Roles: "Roles",
    Roles_Create: "Roles.Create",
    Roles_Edit: "Roles.Edit",
    Roles_EditClaims: "Roles.EditClaims",

    Dictionaries: "Dictionaries",
    Dictionaries_Create: "Dictionaries.Create",
    Dictionaries_Edit: "Dictionaries.Edit",

    Settings: "Settings",
    Settings_Change: "Settings.Change",

    Central_Statistics: "Central.Statistics",

    CustomerCompanies: "CustomerCompanies",
    CustomerCompanies_Create: "CustomerCompanies.Create",
    CustomerCompanies_Edit: "CustomerCompanies.Edit",
    CustomerCompanies_ChangeStatus: "CustomerCompanies.ChangeStatus",

    Invoices: "Invoices",
    Invoices_SyncTasks: "Invoices.SyncTasks",
    Invoices_SyncCompanyTasks: "Invoices.SyncCompanyTasks",
    Invoices_InvoiceTasks: "Invoices.InvoiceTasks",
    Invoices_InvoiceTransactions: "Invoices.InvoiceTransactions",
    Invoices_ViewInvoice: "Invoices.ViewInvoice",
    Invoices_SplitInvoice: "Invoices.SplitInvoice",
    Invoices_SendInvoices: "Invoices.SendInvoices",
    Invoices_UploadInvoice: "Invoices.UploadInvoice",
    Invoices_SetConcession: "Invoices.SetConcession",

    Payments: "Payments",
    Payments_BankStatements: "Payments.BankStatements",
    Payments_Assign: "Payments.Assign",
    Payments_Reassign: "Payments.Reassign",
    Payments_undoAssign: "Payments.UndoAssign",
    Payments_Refund: "Payments.Refund",
    Payments_Archive: "Payments.Archive",

    Reports: "Reports",
    Reports_MonthDataAggregationMatrix: "Reports.MonthDataAggregationMatrix",
    Reports_SummaryBalancesAndFlow: "Reports.SummaryBalancesAndFlow",
    Reports_CompanyDetailedBalancesAndFlow: "Reports.CompanyDetailedBalancesAndFlow"
};