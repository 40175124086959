import axios from 'axios';
import { from } from 'rxjs';

class SettingService {
    getSettings() {
        let promise = axios.get(`${process.env.REACT_APP_API_URL}Configuration/settings`, { enableLoader: true });
        return from(promise);
    }

    saveSetting(model) {
        let promise = axios.post(`${process.env.REACT_APP_API_URL}Configuration/setting`, model);
        return from(promise);
    }
}

export default new SettingService();