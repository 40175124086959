import React from 'react';

export default class Pagination extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { totalItems, itemsPerPage, page } = this.props;
        if (!totalItems) return null;

        const pagesToSee = this.props.pagesToSee || 10;
        const pageCount = Math.ceil(totalItems / itemsPerPage);

        const pagesToEnumerate = [];
        const start = Math.min(page >= pagesToSee - 1 ? page - 1 : 0, Math.max(pageCount - pagesToSee, 0));
        const end = Math.min((page >= pagesToSee - 1 ? page - 1 : 0) + pagesToSee, pageCount);

        for (let i = start; i < end; i++) {
            if (i === 0 || i === pageCount - 1) continue;
            pagesToEnumerate.push(i);
        }

        return (
            <ul className="pagination pagination-sm">
                <li className={`page-item ${page == 0 ? "disabled" : ""}`}>
                    <a className="page-link clickable" onClick={e => { (page != 0 && this.props.onChange) ? this.props.onChange(page - 1) : e.preventDefault(); }}>‹</a>
                </li>

                {
                    pageCount > 0 &&
                    (
                        <li className={`page-item ${page == 0 ? "active" : ""}`}>
                            <a className="page-link clickable" onClick={e => { (page != 0 && this.props.onChange) ? this.props.onChange(0) : e.preventDefault(); }}>1</a>
                        </li>
                    )
                }

                {
                    page + 2 > pagesToSee &&
                    (
                        <li className="page-item">
                            <a className="page-link clickable" onClick={e => { this.props.onChange ? this.props.onChange(pagesToEnumerate[0] - 1) : e.preventDefault(); }}>...</a>
                        </li>
                    )
                }

                {
                    pagesToEnumerate.map(i => (
                        <li key={i} className={`page-item ${page == i ? "active" : ""}`}>
                            <a className="page-link clickable" onClick={e => { (page != i && this.props.onChange) ? this.props.onChange(i) : e.preventDefault(); }}>
                                {i + 1}
                            </a>
                        </li>
                    ))
                }

                {
                    (Math.min((page >= pagesToSee - 1 ? page - 1 : 0) + pagesToSee)) < pageCount - 2 &&
                    (
                        <li className="page-item">
                            <a className="page-link clickable"
                                onClick={e => { this.props.onChange ? this.props.onChange(pagesToEnumerate[pagesToEnumerate.length - 1] + 1) : e.preventDefault(); }}>
                                ...
                            </a>
                        </li>
                    )
                }

                {
                    pageCount > 1 &&
                    (
                        <li className={`page-item ${pageCount - 1 == page ? "active" : ""}`}>
                            <a className="page-link clickable" onClick={e => { (pageCount - 1 != page && this.props.onChange) ? this.props.onChange(pageCount - 1) : e.preventDefault(); }}>{pageCount}</a>
                        </li>
                    )
                }

                <li className={`page-item ${page + 1 >= pageCount ? "disabled" : ""}`}>
                    <a className="page-link clickable"
                        onClick={e => { (page + 1 < pageCount && this.props.onChange) ? this.props.onChange(page + 1) : e.preventDefault(); }}>›</a>
                </li>

                {
                    totalItems > 0 &&
                    <li className="page-item ml-3">
                        <a className="page-link page-total">
                            სულ: {totalItems}
                        </a>
                    </li>
                }
            </ul>
        );
    }
}