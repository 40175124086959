import React from "react";

import { Modal, ModalHeader } from 'reactstrap';
import ModalDrag from './modalDrag';

export default class ModalComponent extends React.Component {
    render() {
        const { show, onClose, size, title, children } = this.props;

        return <ModalDrag>
            <Modal isOpen={show} toggle={onClose} size={size} backdrop={false}>
                {
                    title &&
                    <ModalHeader toggle={onClose} style={{cursor: 'move'}}>
                        {title}
                    </ModalHeader>
                }
                {children}
            </Modal>
        </ModalDrag>;
    }
}