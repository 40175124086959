import axios from 'axios';
import { from } from 'rxjs';

import { CacheService } from './cacheService';

class DictionaryService extends CacheService {
    getConfig() {
        const promise = axios.get(`${process.env.REACT_APP_API_URL}Configuration/config`);
        return from(promise);
    }

    getGenders() {
        return this.fromCache("Genders", () => {
            const promise = axios.get(`${process.env.REACT_APP_API_URL}Dictionary/genders`);
            return from(promise);
        });
    }

    getLegalForms() {
        return this.fromCache("LegalForms", () => {
            const promise = axios.get(`${process.env.REACT_APP_API_URL}Dictionary/legalForms`);
            return from(promise);
        });
    }

    getCompanyStates() {
        return this.fromCache("CompanyStates", () => {
            const promise = axios.get(`${process.env.REACT_APP_API_URL}Dictionary/companyStates`);
            return from(promise);
        });
    }

    getTemplatelanguages() {
        return this.fromCache("Templatelanguages", () => {
            const promise = axios.get(`${process.env.REACT_APP_API_URL}Dictionary/templatelanguages`);
            return from(promise);
        });
    }

    getTimeUnits() {
        return this.fromCache("TimeUnits", () => from(axios.get(`${process.env.REACT_APP_API_URL}Dictionary/timeUnits`)));
    }

    getUsers(username, fullName) {
        const promise = axios.get(`${process.env.REACT_APP_API_URL}Dictionary/users`,
            { enableLoader: true, params: { username, fullName, offset: 0, count: 50 } });
        return from(promise);
    }

    getHRManagers(username, fullName, companyId) {
        const promise = axios.get(`${process.env.REACT_APP_API_URL}Dictionary/hrManagers`,
            { enableLoader: true, params: { username, fullName, companyId, offset: 0, count: 50 } });
        return from(promise);
    }

    getCompanyTypes() {
        return this.fromCache("CompanyTypes", () => from(axios.get(`${process.env.REACT_APP_API_URL}Dictionary/companyTypes`)));
    }
}

export default new DictionaryService();