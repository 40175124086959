const timeUnits = {
    ms: { key: 'ms', value: 1 },
    s: { key: 'ss', value: 1000, tpKey: 'fpSecond', tlKey: 'flSecond' },
    m: { key: 'mm', value: 60, tpKey: 'fpMinute', tlKey: 'flMinute' },
    h: { key: 'HH', value: 60, tpKey: 'fpHoure', tlKey: 'flHoure' },
    d: { key: 'dd', value: 24, tpKey: 'fpDay', tpKey2: 'fpWeek', tlKey: 'flDay', tlKey2: 'flWeek' },
    M: { key: 'MM', value: 30.416, tpKey: 'fpMonth', tlKey: 'flMonth' },
    y: { key: 'yyyy', value: 12, tpKey: 'fpYear', tlKey: 'flYear' }
}

class DateHelperService {
    serverDateTimeFormat = `${timeUnits.y.key}-${timeUnits.M.key}-${timeUnits.d.key}T${timeUnits.h.key}:${timeUnits.m.key}:${timeUnits.s.key}`;
    serverDateFormat = `${timeUnits.y.key}-${timeUnits.M.key}-${timeUnits.d.key}`;
    dateFormat = `${timeUnits.d.key}-${timeUnits.M.key}-${timeUnits.y.key}`;
    dateTimeFormat = `${timeUnits.d.key}-${timeUnits.M.key}-${timeUnits.y.key} ${timeUnits.h.key}:${timeUnits.m.key}:${timeUnits.s.key}`;
    smallDateTimeFormat = `${timeUnits.d.key}-${timeUnits.M.key}-${timeUnits.y.key} ${timeUnits.h.key}:${timeUnits.m.key}`;

    format(date, format = this.serverDateTimeFormat, toUtc = false) {
        if (!date) return "";
        if (typeof date == "string") date = new Date(date);
        if (toUtc) date = this.add(date, new Date().getTimezoneOffset(), 'm');

        let year = date.getFullYear().toString();

        let month = (date.getMonth() + 1).toString();
        if (month.length == 1) month = `0${month}`;

        let day = date.getDate().toString();
        if (day.length == 1) day = `0${day}`;

        let hour = date.getHours().toString();
        if (hour.length == 1) hour = `0${hour}`;

        let minute = date.getMinutes().toString();
        if (minute.length == 1) minute = `0${minute}`;

        let second = date.getSeconds().toString();
        if (second.length == 1) second = `0${second}`;

        return format
            .replace(timeUnits.y.key, year)
            .replace(timeUnits.M.key, month)
            .replace(timeUnits.d.key, day)
            .replace(timeUnits.h.key, hour)
            .replace(timeUnits.m.key, minute)
            .replace(timeUnits.s.key, second);
    }

    parse(date, format = this.serverDateTimeFormat, fromUtc = false) {
        if (!date) return null;

        let yearIndex = format.indexOf(timeUnits.y.key);
        let year = Number(date.substring(yearIndex, yearIndex + 4));

        let monthIndex = format.indexOf(timeUnits.M.key);
        let month = Number(date.substring(monthIndex, monthIndex + 2)) - 1;

        let dayIndex = format.indexOf(timeUnits.d.key);
        let day = Number(date.substring(dayIndex, dayIndex + 2));

        let hourIndex = format.indexOf(timeUnits.h.key);
        let hour = hourIndex == -1 ? 0 : Number(date.substring(hourIndex, hourIndex + 2));

        let minuteIndex = format.indexOf(timeUnits.m.key);
        let minute = minuteIndex == -1 ? 0 : Number(date.substring(minuteIndex, minuteIndex + 2));

        let secondIndex = format.indexOf(timeUnits.s.key);
        let second = secondIndex == -1 ? 0 : Number(date.substring(secondIndex, secondIndex + 2));

        let value = new Date(year, month, day, hour, minute, second);
        if (fromUtc) value = this.add(value, new Date().getTimezoneOffset() * -1, 'm');

        return value;
    }

    dateDiff(dateFrom, dateTo, unit = 'd') {
        if (unit == 'y') {
            let yearDiff = dateTo.getFullYear() - dateFrom.getFullYear();
            let tempDate;
            if (yearDiff < 0) {
                yearDiff *= -1;
                tempDate = dateFrom;
                dateFrom = dateTo;
                dateTo = tempDate;
            }

            while (yearDiff) {
                if (new Date(dateFrom.getFullYear() + yearDiff, dateFrom.getMonth(), dateFrom.getDate(),
                    dateFrom.getHours(), dateFrom.getMinutes(), dateFrom.getSeconds(), dateFrom.getMilliseconds()) <= dateTo)
                    return yearDiff * (tempDate ? -1 : 1);
                yearDiff--;
            }
            return 0;
        }

        let diff = dateTo.getTime() - dateFrom.getTime();
        let { ms, s, m, h, d, M } = timeUnits;

        for (let u of [ms, s, m, h, d, M]) {
            diff /= u.value;
            if (u.key == unit) break;
        }

        return Math.floor(diff);
    }

    getDate(date) {
        if (!date) return null;
        if (typeof date == "string") date = new Date(date);

        return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
    }

    add(date, value, unit = 'd') {
        if (!date) return null;
        if (typeof date == "string") date = new Date(date);

        return new Date(
            (unit == 'y' ? date.getFullYear() + value : date.getFullYear()),
            (unit == 'M' ? date.getMonth() + value : date.getMonth()),
            (unit == 'd' ? date.getDate() + value : date.getDate()),
            (unit == 'h' ? date.getHours() + value : date.getHours()),
            (unit == 'm' ? date.getMinutes() + value : date.getMinutes()),
            (unit == 's' ? date.getSeconds() + value : date.getSeconds()),
            (unit == 'ms' ? date.getMilliseconds() + value : date.getMilliseconds()),
        );
    }

    split(date, format = this.serverDateTimeFormat, fromUtc = false) {
        if (!date) return {};
        if (typeof date == "string") date = this.parse(date, format, fromUtc);

        return {
            year: date.getFullYear(),
            month: date.getMonth(),
            day: date.getDate(),
            houre: date.getHours(),
            munute: date.getMinutes(),
            second: date.getSeconds(),
            millisecond: date.getMilliseconds()
        }
    }

    get utcNow() {
        return this.add(new Date(), new Date().getTimezoneOffset(), 'm');
    }
}

export default new DateHelperService();