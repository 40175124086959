import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar'

import Pagination from './pagination';
import Tooltip from './tooltip';

import { commonService, dateHelperService } from '../services/imports';
import { defaults } from '../models/imports';

export default class DataTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            visibleColumns: [],
            sortColumn: null,
            filterText: ''
        };

        this.tableRef = null;
        this.identifier = commonService.guid();
    }

    get tableHeight() {
        if (!this.props.height && !this.props.heightDiff) return {};
        if (this.props.height) return { 'height': this.props.height };
        if (!this.tableRef) return {};

        const rect = this.tableRef.parentElement.getBoundingClientRect();
        let diff = this.props.heightDiff;
        if (diff === true) diff = '50px';

        return { 'height': `calc(100vh - ${Math.round(rect.y)}px - ${diff})` };
    }

    sort = () => {
        if (this.props.disableSort) return;
        if (!this.state.sortColumn || !this.state.sortColumn.column.key) return;

        this.setState(state => {
            const sortColumn = state.sortColumn;
            const data = [...state.data];

            data.sort((a, b) => {
                let aValue = sortColumn.column.key ? a[sortColumn.column.key] : sortColumn.column.valueFn(a);
                let bValue = sortColumn.column.key ? b[sortColumn.column.key] : sortColumn.column.valueFn(b);

                if (aValue < bValue) return - 1;
                else if (aValue > bValue) return 1;
                return 0;
            });

            if (sortColumn.desc) data.reverse();
            return { data };
        });
    }

    filter = () => {
        if (this.props.disableFilter) return;

        this.setState((state, props) => {
            const filterText = state.filterText?.toLowerCase()?.trim() || '';
            if (!filterText)
                return { data: [...props.source] };

            const data = [];
            for (let item of props.source) {
                for (let column of state.visibleColumns) {
                    if (column.disableFilter || !column.key) continue;

                    let value = column.check ? item[column.key] : this.getRowValue(item, column);
                    value = (value || "") && value.toString().toLowerCase().trim();

                    if (value.indexOf(filterText) != -1) {
                        data.push(item);
                        break;
                    }
                }
            }

            return { data };
        });
    }

    getColumnClass = column => {
        let columnClass = '';
        if (!this.props.disableSort) columnClass += " cursor-default";
        if (column.numeric) columnClass += " col-numeric";

        if (!this.state.sortColumn || this.state.sortColumn.column !== column) return columnClass;
        columnClass += ` ${this.props.sortableHeaderClass === undefined ? "table-secondary" : this.props.sortableHeaderClass}`;

        return columnClass;
    }

    getMinColumnWidth = col => {
        let minWidth = col.minWidth;

        if (!minWidth) {
            if (col.check) minWidth = 50;
            else minWidth = 100;
        }

        return minWidth;
    }

    getColumnStyle = column => {
        let style = column.colStyle || {};

        if (!style.minWidth) style['minWidth'] = this.getMinColumnWidth(column) + 'px';
        if (column.check && style["textAlign"] === undefined) style["textAlign"] = "center";
        if (!column.colStyle) style['paddingLeft'] = '2px';

        return style;
    }

    columnClick = (event, column) => {
        if (column.colClick) column.colClick(event, column);

        if (this.props.disableSort) return;
        if (column.disableSort || !column.key) return;

        this.setState(state => {
            if (column.disableSort)
                return { sortColumn: null };
            else if (state.sortColumn && state.sortColumn.column == column)
                return { sortColumn: { ...state.sortColumn, ...{ desc: !state.sortColumn.desc } } };
            else
                return { sortColumn: { column: column, desc: false } };
        }, () => { this.sort(); });
    }

    getRowKey = (row, index) => {
        const column = this.props.columns.find(x => x.identity);

        if (column) return this.getRowValue(row, column);
        return index;
    }

    getRowClass = row => {
        let rowClass = '';
        if (!this.props.disableSelection) rowClass += " clickable";

        const selected = this.props.selected;
        let isSelected = selected && this.props.columns && !this.props.disableSelection;
        if (isSelected) {
            const column = this.props.columns.find(x => x.identity);
            if (column) isSelected = this.getRowValue(row, column) === this.getRowValue(selected, column);
            else isSelected = this.selected === row;
        }

        if (!isSelected) {
            if (!this.props.rowClass) return rowClass;
            return `${rowClass} ${this.props.rowClass(row)}`;
        }

        rowClass += ` ${this.props.selectedRowClass === undefined ? "table-active" : this.props.selectedRowClass(row)}`;
        return rowClass;
    }

    isCellDisabled = (row, column) => {
        let disabled = column.cellDisabled;

        if (disabled && typeof column.cellDisabled === 'function')
            disabled = column.cellDisabled(row);

        return !!disabled;
    }

    getRowValue = (row, column) => {
        if (column.check && !column.cellHtml) {
            return {
                __html: `<div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" ${this.isCellDisabled(row, column) ? 'disabled' : ''}
                                ${row[column.key] ? 'checked' : ''}>
                            <label class="custom-control-label"></label>
                        </div>`};
        }


        if (column.cellHtml) return { __html: column.cellHtml(row) };
        if (column.valueFn) return column.valueFn(row);

        if (column.numeric) {
            if (typeof column.numeric === "boolean") return commonService.formatNumber(row[column.key], { separator: ',' });
            return commonService.formatNumber(row[column.key], { digits: column.numeric.digits, trim: column.numeric.trim, separator: column.numeric.separator ?? ',' });
        }
        if (column.date) {
            if (typeof column.date === "string") return dateHelperService.format(row[column.key], column.date);
            return dateHelperService.format(row[column.key], column.date.format);
        }
        return row[column.key];
    }

    rowClick = row => {
        if (this.props.disableSelection) return;
        this.props.onSelectedChange && this.props.onSelectedChange(row);
    }

    getCellClass = (column, row) => {
        let cellClass = '';

        if (typeof column.cellClass == 'function') {
            cellClass = `${column.cellClass(row) || ''}`;
        }
        else {
            cellClass = `${column.cellClass || ''}`;
        }

        if (column.numeric) cellClass += " col-numeric";
        else if (column.check && cellClass["text-center"] === undefined) cellClass += " text-center";
        else if (column.singleLine && cellClass["col-ellipsis"] === undefined) cellClass += " col-ellipsis";

        return cellClass.trim();
    }

    getCellStyle = column => {
        return column.cellStyle;
    }

    cellClick = (event, column, row) => {
        if (!column.cellClick || this.isCellDisabled(row, column)) return;
        column.cellClick(event, row);
    }

    cellChange = (e, row, col) => {
        let value = e.target.value;

        if (col.numeric) {
            value = Number(value);

            if (isNaN(value)) {
                e.target.value = this.getRowValue(row, col);
                return;
            }
        }

        col.edit && col.edit(row, value);
    }

    cellKeyPress = (e, row, col) => {
        if (col.handleEditPress) {
            if (col.editPress) col.editPress(e, row);
            return;
        }
        if (!(e.keyCode == 37 || e.keyCode == 39 || e.keyCode == 38 || e.keyCode == 40)) {
            if (col.editPress) col.editPress(e, row);
            return
        }

        const rowIndex = this.state.data.indexOf(row);
        const columns = this.state.visibleColumns.filter(x => x.editable);
        const columnIndex = columns.indexOf(col);

        const select = control => {
            e.preventDefault();
            control.focus();
            control.select();
        }

        if (e.keyCode == 37) {//left
            if (columnIndex == 0 || e.target.selectionStart !== 0) return;

            const prevCol = columns[columnIndex - 1];
            const td = e.target.parentElement.parentElement.querySelector(`td[data-key="${prevCol.key}"]`);
            if (!td || !td.children?.length) return;

            select(td.children[0]);
        }
        else if (e.keyCode == 39) {//right
            if (columnIndex == columns.length - 1 || e.target.selectionEnd < e.target.value?.length) return;

            const nextCol = columns[columnIndex + 1];
            const td = e.target.parentElement.parentElement.querySelector(`td[data-key="${nextCol.key}"]`);
            if (!td || !td.children?.length) return;

            select(td.children[0]);
        }
        else if (e.keyCode == 38) {//up
            if (rowIndex == 0) return;

            let row = e.target.parentElement.parentElement.previousElementSibling;
            let td;
            do {
                td = row && row.querySelector(`td[data-key="${col.key}"]`);
                row = row && row.previousElementSibling;
            }
            while (!td && row)

            if (!td || !td.children?.length) return;
            select(td.children[0]);
        }
        else if (e.keyCode == 40) {//down
            if (rowIndex == this.state.data.length - 1) return;

            let row = e.target.parentElement.parentElement.nextElementSibling;
            let td;
            do {
                td = row && row.querySelector(`td[data-key="${col.key}"]`);
                row = row && row.nextElementSibling;
            }
            while (!td && row)

            if (!td || !td.children?.length) return;
            select(td.children[0]);
        }
    }

    sumColumnValue = column => {
        let sum = 0;

        for (let item of this.state.data) {
            sum += item[column.key];
        }

        return commonService.formatNumber(sum);
    }

    pageChanged = (event) => {
        this.page = event;
        this.props.onPageChange && this.props.onPageChange(event);
    }

    tableAttached = el => {
        const prevRef = this.tableRef;
        this.tableRef = el;
        if (prevRef === el) return;

        if (this.observer) {
            this.observer.disconnect();
            this.observer = undefined;
        }

        if (IntersectionObserver && el) {
            this.observer = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting === true) {
                    this.forceUpdate();
                }
            }, { threshold: [0] });

            this.observer.observe(this.tableRef);
        }
    }

    componentDidMount() {
        this.setState((state, props) => {
            return {
                visibleColumns: props.columns.filter(x => !!x.name),
                data: [...props.source]
            };
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.columns === this.props.columns && prevProps.source === this.props.source) return;

        this.setState((state, props) => {
            const newState = {
                data: [...props.source]
            };

            if (prevProps.columns !== this.props.columns) {
                newState.visibleColumns = props.columns.filter(x => !!x.name);
            }

            return newState;
        }, () => {
            this.sort();
            this.filter();
        });
    }

    componentWillUnmount() {
        if (this.observer) this.observer.disconnect();
    }

    render() {
        const {
            noHeader, disableFilter, disablePaging,
            className, headerClass, filterWidth, enableSum,
            page, totalItems, itemsPerPage, maxPages
        } = this.props;

        const { visibleColumns, filterText, sortColumn, data } = this.state;

        if (!visibleColumns.length) return null;
        return <React.Fragment>
            <PerfectScrollbar className="table-grid" style={this.tableHeight}>
                <table ref={this.tableAttached} className={`${className} mb-0`}>
                    {
                        !noHeader &&
                        <thead>
                            <tr className={headerClass || 'table-secondary'}>
                                {
                                    visibleColumns.map((column, colIndex) => (
                                        <th key={column.key || colIndex} colSpan={column.colspan} style={this.getColumnStyle(column)}
                                            className={this.getColumnClass(column)}
                                            onClick={e => { this.columnClick(e, column); }}>
                                            {
                                                !column.colHtml &&
                                                <b>{!column.hidden && column.name}</b>
                                            }
                                            {
                                                column.colHtml &&
                                                <span dangerouslySetInnerHTML={{ __html: column.colHtml(column) }}></span>
                                            }
                                            {
                                                (sortColumn && sortColumn.column === column) &&
                                                <i className={`fa ml-1 fa-angle-double-${sortColumn.desc ? 'down' : 'up'}`}></i>
                                            }
                                        </th>
                                    ))
                                }
                            </tr>
                        </thead>
                    }
                    <tbody>
                        {
                            data.map((row, i) => (
                                <tr key={this.getRowKey(row, i)}
                                    className={this.getRowClass(row)}
                                    onClick={e => { this.rowClick(row); }}>
                                    {
                                        visibleColumns.map((column, colIndex) => {
                                            const isHtml = column.cellHtml || column.check;

                                            let isEditable = column.editable;
                                            if (isEditable && typeof isEditable === "function")
                                                isEditable = isEditable(row);

                                            return <React.Fragment key={column.key || colIndex}>
                                                {
                                                    (!isEditable && !isHtml) &&
                                                    <td style={this.getCellStyle(column)}
                                                        className={this.getCellClass(column, row)}
                                                        onClick={e => { this.cellClick(e, column, row) }}>
                                                        {
                                                            column.singleLine &&
                                                            <Tooltip placement='bottom' tooltip={this.getRowValue(row, column)} onlyTruncated>
                                                                <div className='content'>
                                                                    {this.getRowValue(row, column)}
                                                                </div>
                                                            </Tooltip>
                                                        }
                                                        {
                                                            !column.singleLine &&
                                                            this.getRowValue(row, column)
                                                        }
                                                    </td>
                                                }
                                                {
                                                    (!isEditable && isHtml) &&
                                                    <td dangerouslySetInnerHTML={this.getRowValue(row, column)}
                                                        style={this.getCellStyle(column)}
                                                        className={this.getCellClass(column, row)}
                                                        onClick={e => { this.cellClick(e, column, row) }}>
                                                    </td>
                                                }
                                                {
                                                    (isEditable && !isHtml) &&
                                                    <td data-key={column.key}
                                                        style={this.getCellStyle(column)}
                                                        className={this.getCellClass(column, row)}>
                                                        {
                                                            column.numeric &&
                                                            <input type="text" className="editable-column"
                                                                style={{ width: column.minWidth ? (column.minWidth + 'px') : '100%' }}
                                                                value={this.getRowValue(row, column) || ''}
                                                                onChange={e => { this.cellChange(e, row, column) }}
                                                                onKeyDown={e => { this.cellKeyPress(e, row, column) }} />
                                                        }
                                                        {
                                                            !column.numeric &&
                                                            <textarea className="editable-column" rows="1"
                                                                style={{ width: column.minWidth ? (column.minWidth + 'px') : '100%' }}
                                                                value={this.getRowValue(row, column) || ''}
                                                                onChange={e => { this.cellChange(e, row, column) }}
                                                                onKeyDown={e => { this.cellKeyPress(e, row, column) }}></textarea>
                                                        }
                                                    </td>
                                                }
                                            </React.Fragment>;
                                        })
                                    }
                                </tr>
                            ))
                        }
                        {
                            enableSum &&
                            <tr>
                                {
                                    visibleColumns.map((column, colIndex) => (
                                        <td key={column.key || colIndex}
                                            style={this.getCellStyle(column)}
                                            className={this.getCellClass(column, null)}>
                                            {
                                                column.sum &&
                                                <b>{this.sumColumnValue(column)}</b>
                                            }
                                        </td>
                                    ))
                                }
                            </tr>
                        }
                    </tbody>
                </table >

                <div className="fill"></div>
            </PerfectScrollbar>
            {
                (!disablePaging || !disableFilter) &&
                <div className="row mt-2">
                    <div className={`col-${12 - (filterWidth || 3)}`}>
                        {
                            !disablePaging &&
                            <Pagination totalItems={totalItems} page={page} onChange={this.pageChanged}
                                itemsPerPage={itemsPerPage || defaults.itemsPerPage}
                                maxPages={maxPages || defaults.maxPages} />
                        }

                    </div>
                    <div className={`col-${filterWidth || 3}`}>
                        {
                            !disableFilter &&
                            <input type="search" className="form-control input-sm" placeholder="Search"
                                value={filterText || ''} autoComplete="off"
                                onChange={e => { this.setState({ filterText: e.target.value }, this.filter); }}
                            />
                        }
                    </div>
                </div>
            }
        </React.Fragment>;
    }
}